import { useUserStore } from "@/store/modules/user";
import { useCartStore } from "@/store/modules/cart";

export default defineNuxtRouteMiddleware(async (to, _) => {
	// skip middleware on server
	if (import.meta.server) return;

	const userStore = useUserStore();
	const { getCartData } = useCartStore();
	const { user } = storeToRefs(userStore);
	const jwtToken = useCookie<string>("jwt_token");

	if (jwtToken.value) {
		if (user.value && to.path === "/sign-in") {
			await getCartData();
			return abortNavigation();
		} else if ((user.value && to.path !== "/sign-in") || (user.value && to.path !== "/my-cart")) {
			await getCartData();
		} else if (user.value && to.path === "/my-cart") {
			return true;
		} else {
			try {
				await userStore.getUserInfo();
				await getCartData();
			} catch (error) {
				return navigateTo("/sign-in");
			}
		}
	} else if (to.path === `/my-cart`) {
		return navigateTo("/sign-in");
	}
});
