import { default as about_45usVS9jrCBG8qMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/about-us.vue?macro=true";
import { default as index1oLpVQM630Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/account-activation/index.vue?macro=true";
import { default as authEKg5mhO8L4Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/auth.vue?macro=true";
import { default as _91article_93F3tnnuzWxtMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/blog/[article].vue?macro=true";
import { default as indexOPhnVEHGnUMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/blog/index.vue?macro=true";
import { default as _91slug_934fBXkAjwILMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/collections/[slug].vue?macro=true";
import { default as indexHMtJmXGsyxMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/collections/index.vue?macro=true";
import { default as contact_45usGtW1TBUM2nMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/contact-us.vue?macro=true";
import { default as indexWy0cFf8jJIMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/index.vue?macro=true";
import { default as _91merchant_93dcxSqmIZYNMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/merchants/[merchant].vue?macro=true";
import { default as index6Z0HAnUaCaMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/merchants/index.vue?macro=true";
import { default as my_45cartRIYXCYJ1AmMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/my-cart.vue?macro=true";
import { default as order_45confirmationvmPv4gkNX7Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/order-confirmation.vue?macro=true";
import { default as _91id_930z3CkXPOB1Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/orders/[id].vue?macro=true";
import { default as indexa9kZb3h1fOMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/orders/index.vue?macro=true";
import { default as sign_45inp5mNIP8Bi4Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/sign-in.vue?macro=true";
import { default as _91id_93OddcUIWlkzMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/textiles/[id].vue?macro=true";
import { default as indexi67CVw1UcZMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/textiles/index.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "account-activation",
    path: "/account-activation",
    meta: index1oLpVQM630Meta || {},
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/account-activation/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: authEKg5mhO8L4Meta || {},
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "blog-article",
    path: "/blog/:article()",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/blog/[article].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "merchants-merchant",
    path: "/merchants/:merchant()",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/merchants/[merchant].vue").then(m => m.default || m)
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/merchants/index.vue").then(m => m.default || m)
  },
  {
    name: "my-cart",
    path: "/my-cart",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/my-cart.vue").then(m => m.default || m)
  },
  {
    name: "order-confirmation",
    path: "/order-confirmation",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "textiles-id",
    path: "/textiles/:id()",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/textiles/[id].vue").then(m => m.default || m)
  },
  {
    name: "textiles",
    path: "/textiles",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/textiles/index.vue").then(m => m.default || m)
  }
]