export default defineNuxtPlugin(app => {
	app.hook("app:created", async () => {
		const platformStore = usePlatformStore();
		const url = useRequestURL();
		const platformUrl =
			url.hostname !== "localhost" ? url.hostname.replaceAll(".", "-") : "responsiblematerialsourcing-com";

		await platformStore.getPlatform(platformUrl);
	});
});
